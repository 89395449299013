import React, { useEffect } from "react";
import Tree from "./Tree";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import MotivationText from "./motivation";

const Home = () => {

  const [length, setLength] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth); // İçerisinde pencere genişliğini saklayacak bir state oluşturuyoruz
  const [isMobile, setIsMobile] = React.useState(false); // Küçük ekranda olup olmadığımızı tutacak bir state oluşturuyoruz
  const [jsonString, setJsonString] = React.useState("");
  const [jsonObject, setJsonObject] = React.useState(null);
  const [isConvert, setIsConvert] = React.useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Pencere boyutu değiştikçe, pencerenin genişliğini güncelliyoruz
    };

    window.addEventListener("resize", handleResize); // Resize event listener'ını ekliyoruz

    return () => {
      window.removeEventListener("resize", handleResize); // Component unmount olduğunda event listener'ı kaldırıyoruz
    };
  }, []);

  useEffect(() => {
    // Pencere genişliğine göre, mobil boyutun altında olup olmadığımızı kontrol ediyoruz
    if (windowWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  const containerStyle = {
    display: "flex",
    height: "100vh",
    flexDirection: isMobile ? "column" : "row", // Genişlik küçükse, flex direction'ı column yapıyoruz
  };

  const sideStyle = {
    flex: 1,
    display: "table-cell",
    padding: "10px",
    border: "1px solid #ddd",
  };

  const midStyle = {
    flex: 1,
    backgroundColor: "#f0f0f0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: !isMobile ? "column" : "row", // Genişlik küçükse, flex direction'ı column yapıyoruz
  };

  const leftSideStyle = {
    ...sideStyle,
    backgroundColor: "#f0f0f0",
    height: "calc(100% - 20px)",
    flex: 2,
  };

  const rightSideStyle = {
    ...sideStyle,
    backgroundColor: "#f0f0f0",
    overflowY: "auto",
    overflowX: "scroll",
    height: "calc(100% - 20px)",
    flex: 4,
  };
  const textareaStyle = {
    width: "calc(100% - 20px)",
    height: "calc(100% - 100px)",
    padding: "0px",
    resize: "none",
    borderWidth: 1,
    borderRadius: 7,
  };

  const handleStringInputChange = (event) => {
    setJsonString(event.target.value);
  };

  const handleConvertClick = () => {
    setIsConvert(true)
    ReactGA.event({
      category: "execute",
      action: "execute",
      label: "json",
    });
    try {
      const parsedJson = JSON.parse(jsonString);
      setJsonObject(parsedJson);
    } catch (error) {
      setJsonObject("invalid JSON: " + JSON.stringify(error.message));
    }
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={leftSideStyle}>
          <textarea
            style={textareaStyle}
            value={jsonString}
            onChange={handleStringInputChange}
            placeholder="Enter JSON-like string expression here"
          />
          <br />
          <button
            onClick={handleConvertClick}
            style={{
              height: "auto",
              minHeight: 60,
              maxHeight: 130,
              width: "auto",
              cursor: "pointer",
              marginTop: 10,
              backgroundColor: "snow",
              borderRadius: 7,
              borderWidth: 1,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
        --  <span style={{ color: 'red', fontSize:18 }}>must</span>{' '} : <span style={{ color: 'purple', fontSize:18 }}>Beautify JSON thoroughly</span>{' '}   <br />

        --  <span style={{ color: 'red', fontSize:18 }}>nice to have </span>{' '} :  <a style={{fontSize:20}} href="https://buymeacoffee.com/debugger951">..buymeacoffee..</a>
          </button>
          <br />
        </div>
      
       
        <div style={rightSideStyle}>
          {!isConvert ? (
            <MotivationText></MotivationText>
          ) : typeof jsonObject == "string" &&
            jsonObject?.startsWith("invalid JSON:") ? (
            <div>{jsonObject} </div>
          ) : (
            <Tree data={jsonObject} length={length} />
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
