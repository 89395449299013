import React from "react";
import Home from "./components/Home";
import "./App.css";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>JSON Beautifier</title>
        <meta name="description" content="JSON Beautifier: Beautify your JSON data easily." />
        <link rel="canonical" href="https://venusvibes.space/" />
      </Helmet>
      <Home />
    </div>
  );
}

export default App;

