import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  listItem: {
    padding: 4,
  },
  listItemText: {
    flex: "0 7 auto",
  },
  body1: {
    fontWeight: "bold",
  },
  listIcon: {
    minWidth: "unset",
    color: "#ff6464",
  },
}));

const ShowBrackets = ({ data, length }) => {
  const text = length > 1 ? "items" : "item";
  const brackets = Array.isArray(data) ? " [...]" : " {...}";
  return (
    <Typography
      component="span"
      variant="body2"
      color="textSecondary">
      {length > 0 ? `${brackets} // ${length} ${text}` : ``}
    </Typography>
  );
};

const TreeItem = ({ classes, label, value, isArray, index }) => {
  const renderIndex = isArray ? (
    <ListItemText
      style={{ color: "red", marginRight: "10px" }}
      classes={{ root: classes.listItemText }}>
      [{index}] :
    </ListItemText>
  ) : null

  const renderLabel = label ? (
    <ListItemText
      style={{ color: "#8A2BE2", marginRight: "10px", fontFamily: "fantasy" }}
      classes={{ root: classes.listItemText }}>
      {label}:
    </ListItemText>
  ) : null

  return (
    <ListItem
      button className={classes.nested}
      style={{ flexDirection: "row", display: "flex", whiteSpace: "nowrap" }}>
      <div style={{ display: "flex", userSelect: "all" }}>
        {renderIndex}
        {renderLabel}

        <div>
          <ListItemText
            style={{ color: "#000080", fontFamily: "verdana", display: "flex", userSelect: "all" }}>
            {
              value === null || value === undefined || (typeof value === "string" && value?.trim().length < 1)
                ?
                `" "`
                :
                typeof value === "string"
                  ?
                  `"${value.toString()}"`
                  :
                  value
            }
          </ListItemText>
        </div>
      </div>
    </ListItem>
  );
};



const Tree = ({ data, length, parentName = "Your JSON Data", classes, open: externalOpen, parentIndex }) => {
  const [open, setOpen] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);

  React.useEffect(() => {
    setOpen(externalOpen);
    if (externalOpen) {
      setNestedOpen(true);
    }
  }, [externalOpen]);

  return (
    <>
      {data && (
        <ListItem
          button
          onClick={() => Object.keys(data)?.length && setOpen(!open)}
          classes={{ root: classes.listItem }}
        >
          <ListItemIcon
            key={uuid()}
            classes={{ root: classes.listIcon }}>
            {open ?
              <ExpandMore />
              :
              <ChevronRight />}
          </ListItemIcon>

          <ListItemText
            key={uuid()}
            style={{display:"flex", flexDirection:"row", whiteSpace:"nowrap"}}>
            <b style={{ color: parentName.length ? "black" : "red" }}>{parentName.length ? parentName : `[${parentIndex}]`}</b>
            <b> {!open && <ShowBrackets data={data} length={length} />} </b>

          </ListItemText>
        </ListItem>
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ paddingLeft: "30px" }}
      >
        <List
          component="div"
          style={{ padding: 0 }}>
          {data &&
            Object.keys(data).map((key, index) => {
              const value = data[key];
              return value != null && typeof value === "object" ? (
                <Tree
                  key={uuid()}
                  data={value}
                  parentName={Array.isArray(data) ? "" : key}
                  length={Object.keys(value).length}
                  classes={classes}
                  open={!nestedOpen && open && data.length && Array.isArray(data)}
                  parentIndex={index}
                />
              ) : (
                <TreeItem
                  key={uuid()}
                  classes={classes}
                  label={Array.isArray(data) ? "" : key}
                  value={value || ""}
                  isArray={Array.isArray(data)}
                  index={index} />
              );
            })}
        </List>
      </Collapse>
    </>
  );
};



export default function StyledTree({ data, length, parentName = "Your JSON Data" }) {
  const classes = useStyles();

  return <Tree
    data={data}
    length={length}
    parentName={parentName}
    classes={classes}
    open={true} />;
}
