import React from "react";
import '../App.css'

function MotivationText() {
  return (
    <div > 
      <h1>Hey there, programmer friend!</h1>
      <h3>
        I want to tell you something. This world is waiting for your code and
        creativity. Every line of code tells a story, and each line is dedicated
        to solving a problem.
      </h3>
      <h3>
        Yes, sometimes coding can be tough. The debugging process can test your
        patience. But remember, great innovations and amazing projects emerge
        when these challenges are overcome.
      </h3>
      <h3>
        Writing code is an art. When you sit in front of the computer screen, it
        feels like standing in front of a blank canvas. Use your imagination and
        fill that canvas! While writing your code, realize your dreams. You are
        free, push your limits!
      </h3>
      <h3>
        Remember, each line of code is progress. Every error is an opportunity
        to learn. And every success opens the door to the next big step.
      </h3>
      <h3>
        Believe in yourself. Believe in your potential. And never give up. The
        world needs your creativity and determination. Transform and inspire the
        future with code.
      </h3>
      <h3>
        You have great power within you. Now, it's time to use it! If you ever
        need support or want to connect, feel free to reach out to me at{"... "}
      </h3>
      <h2>
        <a href="mailto:debugger951@gmail.com">debugger951@gmail.com</a>
      </h2>
    </div>
  );
}

export default MotivationText;
